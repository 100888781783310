<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Dokumenti"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <div class="float-right d-flex flex-column" style="gap: 10px;">
            <b-button variant="success" :to="{ name: 'DocumentCreate' }" v-can="'documents.create'">Pievienot</b-button>
            <b-button variant="warning" :to="{ name: 'DocumentView' }">Notīrīt filtru</b-button>
          </div>

          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Dokuments tika veiksmīgi arhivēts!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-row>
            <b-col lg="9" class="my-1">
              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="searchInput"
                      :debounce="300"
                      type="search"
                      placeholder="Meklēt pēc vārda, uzvārda"
                  ></b-form-input>

                </b-input-group>
              </b-form-group>

              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-select
                  v-model="selectedDateType"
                  >
                    <option :value="0" selected>Meklēt pēc dokumenta izdošanas datuma intervāla</option>
                    <option :value="1">Meklēt pēc dokumenta derīguma datuma intervāla</option>
                  </b-form-select>
                  <b-form-input
                      placeholder="Sākuma datums"
                      today-button
                      type="date"
                      v-model="dateStart"
                      reset-button
                      close-button
                      locale="lv"
                  ></b-form-input>
                  <b-form-input
                      placeholder="Beigu datums"
                      today-button
                      type="date"
                      v-model="dateEnd"
                      reset-button
                      close-button
                      locale="lv"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-select
                      v-model="selectedDocumentTypeFilter"
                  >
                    <option value="null" selected disabled>Dokumenta tips</option>
                    <option v-for="(type, index) in documentTypes" :key="index" :value="type.value">{{ type.text }}</option>
                  </b-form-select>
                  <b-form-select
                      v-model="selectedEducationTypeFilter"
                  >
                    <option value="null" selected disabled>Izglītība</option>
                    <option v-for="(type, index) in educationTypes" :key="index" :value="type.value">{{ type.text }}</option>
                  </b-form-select>
                  <b-form-select
                      v-model="selectedEducationFacilityFilter"
                  >
                    <option value="null" selected disabled>Mācību iestādes nosaukums</option>
                    <option v-for="(facility, index) in educationFacilities" :key="index" :value="facility.value">{{ facility.text }}</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-select
                      v-model="selectedCourseFilter"
                  >
                    <option value="null" selected disabled>Kursi</option>
                    <option v-for="(course, index) in courses" :key="index" :value="course.value">{{ course.text }}</option>
                  </b-form-select>

                  <b-form-select
                      v-model="selectedThemeFilter"
                  >
                    <option value="null" selected disabled>Tēma</option>
                    <option v-for="(theme, index) in themes" :key="index" :value="theme.value">{{ theme.text }}</option>
                  </b-form-select>
                  <b-form-input
                      id="filter-input"
                      v-model="selectedCountOfHoursFilter"
                      :debounce="300"
                      type="search"
                      placeholder="Stundu skaits"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="items"
                   :fields="fields"
                   :no-provider-sorting="true"
                   @sort-changed="sortingChanged"
                   v-if="loaded">

            <template #cell(actions)="row">
              <b-button size="sm" variant="warning" @click="$router.push({ name: 'DocumentView', params: {id: row.item.id} })" class="mr-1 mt-2">
                {{ hasPermission('documents.update') ? 'Skatīt/labot' : 'Skatīt' }}
              </b-button>
              <b-button v-if="hasPermission('documents.destroy')" size="sm" variant="danger" class="mt-2" v-b-modal.modal-sm @click="archiveItem(row.item.id, row.index)">
                Arhivēt
              </b-button>
            </template>

            <template #cell(issue_date)="row">
              <span> {{ dateFormat(row.item.issue_date, "dd.mm.yyyy") }} </span>
            </template>

            <template #cell(valid_date)="row">
              <span> {{ row.item.valid_date ? dateFormat(row.item.valid_date, "dd.mm.yyyy") : '' }} </span>
            </template>

          </b-table>
          <template v-if="isBusy && !noData">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Dati tiek lejupielādēti...</strong>
            </div>
          </template>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";

const dateFormat = require('dateformat');

export default {
  name: "List",
  data() {
    return {
      dateFormat: dateFormat,
      documents: null,
      documentTypes: null,
      educationTypes: null,
      educationFacilities: null,
      courses: null,
      themes: null,
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      currentPage: 1,
      noData: false,
      datePicker: {
        dateStart: null,
        dateEnd: null,
      },
      search: null,
      sort: null,
      isBusy: false,
      items: [],
      selectType: 0,
      selectedDocumentType: null,
      selectedEducationType: null,
      selectedEducationFacility: null,
      selectedCourse: null,
      selectedTheme: null,
      numberOfHours: null,
      searchString: '',
      fields: [
        { key: 'name', label: 'Vārds' },
        { key: 'surname', label: 'Uzvārds' },
        { key: 'personal_code', label: 'Pers. kods' },
        { key: 'document_type', label: 'Dokumenta tips', sortable: true },
        { key: 'education_type', label: 'Izglītība', sortable: true },
        { key: 'issue_date', label: 'Dokumenta izdošanas datums', sortable: true },
        { key: 'valid_date', label: 'Derīgs līdz', sortable: true },
        { key: 'education_facility', label: 'Mācību iestādes nosaukums' },
        { key: 'courses', label: 'Kursi' },
        { key: 'theme', label: 'Tēma' },
        { key: 'hourCount', label: 'Stundu skaits' },
        { key: 'actions', label: 'Darbības' },
      ],
      loaded: true,
    }
  },
  mounted() {
    let name = this.$route.params.name
    if(name) {
      let date = new Date();
      date.setFullYear(date.getFullYear()-3);

      this.datePicker.dateStart = date.toISOString();
      this.search = name;
      this.onFilter();
    } else {
      this.getData();
    }

    axios.get('/options').then(response => {
      this.documentTypes = response.data.data.document_types.data
      this.educationTypes = response.data.data.education_types.data
      this.educationFacilities = response.data.data.education_facility.data
      this.courses = response.data.data.courses.data
      this.themes = response.data.data.themes.data
    })

    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight <= 0;
      if (bottomOfWindow && !this.isBusy) {
        this.getData();
      }
    };
  },
  computed: {
    ...mapGetters ({
      User: "Auth/user",
    }),
    searchInput: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
        this.onFilter();
      }
    },
    selectedDateType: {
      get() {
        return this.selectType;
      },
      set(value) {
        this.selectType = value;
        this.onFilter();
      }
    },
    dateStart: {
      get() {
        return this.datePicker.dateStart;
      },
      set(value) {
        this.datePicker.dateStart = value;
        this.onFilter();
      }
    },
    dateEnd: {
      get() {
        return this.datePicker.dateEnd;
      },
      set(value) {
        this.datePicker.dateEnd = value;
        this.onFilter();
      }
    },
    selectedDocumentTypeFilter: {
      get() {
        return this.selectedDocumentType;
      },
      set(value) {
        this.selectedDocumentType = value;
        this.onFilter();
      }
    },
    selectedEducationTypeFilter: {
      get() {
        return this.selectedEducationType;
      },
      set(value) {
        this.selectedEducationType = value;
        this.onFilter();
      }
    },
    selectedEducationFacilityFilter: {
      get() {
        return this.selectedEducationFacility;
      },
      set(value) {
        this.selectedEducationFacility = value;
        this.onFilter();
      }
    },
    selectedCourseFilter: {
      get() {
        return this.selectedCourse;
      },
      set(value) {
        this.selectedCourse = value;
        this.onFilter();
      }
    },
    selectedThemeFilter: {
      get() {
        return this.selectedTheme;
      },
      set(value) {
        this.selectedTheme = value;
        this.onFilter();
      }
    },
    selectedCountOfHoursFilter: {
      get() {
        return this.selectedCountOfHours;
      },
      set(value) {
        this.selectedCountOfHours = value;
        this.onFilter();
      }
    },
  },
  methods: {
    archiveItem(id, index) {
      axios.delete('/documents/'+id).then(response => {

        this.items.splice(index, 1);

        this.created = 'success';
      }).catch(e => {
        this.created = 'error';
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async getData() {
      this.isBusy = true;
      await axios.get('/documents?page[number]=' +
          this.currentPage +
          (this.sort ? '&sort=' + (this.sort.sortDesc ? '-' : '')+this.sort.field : '') +
          this.searchString
      ).then(response => {

        let data = response.data.data;

        if(data.length) {
          this.items = this.items.concat(data);
          this.noData = false;
          this.isBusy = false;
        } else {
          this.noData = true;
        }
      }).catch(e => {
        this.isBusy = false;
      })

      this.currentPage++;
    },
    sortingChanged(ctx) {
      switch(ctx.sortBy) {
        case 'issue_date':
          ctx.sortBy = 'issueDate';
          break;
        case 'valid_date':
          ctx.sortBy = 'validDate';
          break;
      }

      this.sort = {
        'field': ctx.sortBy,
        'sortDesc': ctx.sortDesc,
      }

      this.currentPage = 1;
      this.items = [];
      this.getData();
    },
    onFilter() {
      this.currentPage = 1;
      this.items = [];

      this.searchString = "";
      let dateType = "";

      switch(this.selectType) {
        case 0:
          dateType = 'issueDate';
        break;

        case 1:
          dateType = 'validDate';
        break;
      }

      if(this.search)
        this.searchString += '&filter[name]='+this.search;

      if(this.datePicker['dateStart'] || this.datePicker['dateEnd'])
        this.searchString += '&filter['+dateType+']=';

      if(this.selectedDocumentType) {
        this.searchString += '&filter[documentType]='+this.selectedDocumentType;
      }

      if(this.selectedEducationType) {
        this.searchString += '&filter[educationType]='+this.selectedEducationType;
      }

      if(this.selectedEducationFacility) {
        this.searchString += '&filter[educationFacility]='+this.selectedEducationFacility;
      }

      if(this.selectedCourse) {
        this.searchString += '&filter[course]='+this.selectedCourse;
      }

      if(this.selectedTheme) {
        this.searchString += '&filter[theme]='+this.selectedTheme;
      }

      if(this.selectedCountOfHours) {
        this.searchString += '&filter[additionalCourse]='+this.selectedCountOfHours;
      }

      Object.keys(this.datePicker).forEach(e => {
        if(this.datePicker[e]) {
          if(e === 'dateEnd' && this.datePicker['dateStart']) {
            this.searchString += ','
          }
          this.searchString += this.datePicker[e]
        }
      })

      this.getData();
    },
  },
}
</script>

<style scoped>

</style>